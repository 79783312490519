import {Form, Input} from 'antd';
import {LockOutlined} from '@ant-design/icons';

// https://stackoverflow.com/a/26322921
function validatePassword(value: string): boolean {
    return /[A-Z]/.test(value) &&
        /[a-z]/.test(value) &&
        /[0-9]/.test(value) &&
        /[^A-Za-z0-9]/.test(value) &&
        value.length >= 8;

}

const PasswordFormItem = () => {
    return (
        <Form.Item
            name="password"
            rules={[
                {
                    required: true,
                    message: 'REQUIRED',
                },
                () => ({
                    validator(_, value) {
                        if (validatePassword(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The password that must be at least eight (8) characters including upper and lower case letters, number and symbols like ! " ? $ % ^ &.'));
                    },
                }),
            ]}
        >
            <Input
                prefix={<LockOutlined/>}
                type="password"
                placeholder="Password"
                autoComplete={'current-password'}
            />
        </Form.Item>
    );
}

export default PasswordFormItem;
