import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Typography} from 'antd';

import AuthPage from 'components/AuthPage';
import RegisterForm from './RegisterForm';

const {Text} = Typography;

const RegisterPage = () => {
    const [done, setDone] = useState(window.location.hash === '#registered');

    function onRegister() {
        window.location.hash = '#registered';
        setDone(true);
    }

    if (done) {
        return (
            <AuthPage>
                <h2>Thank you for registering!</h2>
                <p>Please check your email to confirm your address.</p>
            </AuthPage>
        );
    }

    return (
        <AuthPage>
            <RegisterForm onRegister={onRegister}/>
            <Text>Already registered? <Link to={'/login'}>Log In</Link></Text>
        </AuthPage>
    );
}

export default RegisterPage;
