import ReactApexChart from 'react-apexcharts';
import { Row, Col, Typography } from 'antd';

import styles from './BarChart.module.scss';
import barChart from './configs/barChart';

const {Title, Paragraph} = Typography;

const BarChart = () => {
    const items = [
        {
            Title: "3,6K",
            user: "Users",
        },
        {
            Title: "2m",
            user: "Clicks",
        },
        {
            Title: "$772",
            user: "Sales",
        },
        {
            Title: "82",
            user: "Items",
        },
    ];
    return (
        <>
            <div>
                <ReactApexChart
                    className={styles.chart}
                    options={barChart.options as ApexCharts.ApexOptions}
                    series={barChart.series}
                    type="bar"
                    height={220}
                />
            </div>
            <div className={styles.chartInfo}>
                <Title level={5}>Active Users</Title>
                <Paragraph>
                    than last week <span className="text-green">+30%</span>
                </Paragraph>
                <Paragraph>
                    We have created multiple options for you to put together and customise
                    into pixel perfect pages.
                </Paragraph>
                <Row>
                    {items.map((v, index) => (
                        <Col xs={6} xl={6} sm={6} md={6} key={index}>
                            <div className="chart-visitor-count">
                                <Title level={4}>{v.Title}</Title>
                                <span>{v.user}</span>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
}

export default BarChart;
