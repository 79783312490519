import apiClient from './apiClient';
import type {Role} from '../types';

export async function roles() {
    return apiClient
        .get('/roles')
        .then(res => {
                return res.json() as Promise<Role[]>
            }
        )
}
