import {Form, Input, Button, Typography, message} from 'antd';
import {LockOutlined, MailOutlined} from '@ant-design/icons';

import {getErrorMessage} from 'utils';
import {AuthToken} from 'hooks/useAuthToken';

type LoginFormRequest = {
    username: string,
    password: string,
}

const LoginForm = (props: {
    onLogin: (data: AuthToken) => void
}) => {
    async function onFinish(values: LoginFormRequest) {
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);

        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/login`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
            if (res.status !== 200) {
                message.error('Invalid Username or Password.');
            } else {
                props.onLogin(await res.json() as AuthToken);
            }
        } catch (e) {
            message.error(<><b>[Unknown Network Error]</b> {getErrorMessage(e)}</>);
        }
    }

    return (
        <Form
            name="login"
            className={'auth-form'}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Typography.Title>Login</Typography.Title>
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input
                    prefix={<MailOutlined/>}
                    placeholder="Email"
                    autoComplete={'email'}
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                    autoComplete={'current-password'}
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
}

export default LoginForm;
