import {Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import React from 'react';

const PageHeading = (props: {
    title: string,
    onClick?: () => void,
    children?: React.ReactNode
}) => {
    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <h2>{props.title}</h2>
            {props.onClick ? <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={props.onClick}
            /> : null}
            {props.children}
        </div>
    );
};

export default PageHeading;
