import {redirect} from 'react-router-dom';

import type {User} from 'types';
import store from 'store';

import {setCurrentUser} from 'features/currentUser/currentUserSlice';

import {is_super_admin} from 'utils';
import apiClient from './apiClient';


function needsOnboarding(request: Request, user: User) {
    if (!user.connected_account && !is_super_admin(user)) {
        const url = request.url;
        if (!url.endsWith('/onboarding')) {
            return true;
        }
    }
    return false;
}


export async function me(args: { request: Request }) {
    return apiClient
        .get('/auth/me', {expand: ['roles', 'account', 'connected_account']})
        .then(res => {
                return res.json() as Promise<User>
            }
        )
        .then(user => {
            if (needsOnboarding(args.request, user)) {
                return redirect("/onboarding");
            }
            store.dispatch(setCurrentUser(user));
            return user;
        });
}

export async function updateMe(
    data: {first_name?: string|null, last_name?: string|null},
) {
    return apiClient
        .patch('/auth/me', data, {expand: ['roles', 'account']})
        .then(res => {
            return res.json() as Promise<User>
        })
        .then(user => {
            store.dispatch(setCurrentUser(user));
            return user;
        });
}
