import {Avatar, Dropdown, MenuProps} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import React from 'react';
import {UserOutlined} from '@ant-design/icons';
import {useAuthToken} from '../hooks/useAuthToken';

const Profile = () => {
    const setAuthToken = useAuthToken()[1];
    const navigate = useNavigate();

    const items: MenuProps['items'] = [
        {
            label: <Link to="/profile">Profile</Link>,
            key: 'profile',
        },
        {
            type: 'divider',
        },
        {
            label: 'Logout',
            key: 'logout',
        },
    ];

    const onClick: MenuProps['onClick'] = ({ key }) => {
        if (key === 'logout') {
            setAuthToken(null);
            navigate('/login')
        }
    };

    return (
        <Dropdown menu={{ items: items, onClick }} trigger={['click']}>
            <Avatar icon={<UserOutlined />} style={{cursor: 'pointer'}} />
        </Dropdown>
    )
}

export default Profile;
