import {useCurrentUser} from 'hooks/useCurrentUser';


const OnboardingPage = () => {
    const currentUser = useCurrentUser();

    if (!currentUser) {
        return null;
    }

    return (
        <div>Onboard Me.</div>
    );
}

export default OnboardingPage;
