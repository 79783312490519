import apiClient from './apiClient';
import type {User} from '../types';

export async function users() {
    return apiClient
        .get('/users', {expand: ['roles', 'account']})
        .then(res => {
                return res.json() as Promise<User[]>
            }
        )
}

export async function addUser(
    data: {
        email: string,
        first_name?: string | null | undefined,
        last_name?: string | null | undefined,
        roles?: string[]
    }
) {
    return apiClient
        .post('/users', data, {expand: ['roles', 'account']})
        .then(res => {
                return res.json() as Promise<User[]>
            }
        )
}
