import React from 'react';

import {createBrowserRouter, RouterProvider, Navigate} from 'react-router-dom';

import Page from 'components/Page';
import ErrorBoundary from 'routing/ErrorBoundary';

import { me } from 'services/me';
import { users } from 'services/users';
import { confirmEmail } from 'services/confirmEmail';

import ProfilePage from 'pages/profile/ProfilePage';
import UsersPage from 'pages/users/UsersPage';
import DashboardPage from 'pages/dashboard/DashboardPage';

import LoginPage from 'pages/auth/login/LoginPage';
import RegisterPage from 'pages/auth/register/RegisterPage';
import ForgotPasswordPage from 'pages/auth/forgotPassword/ForgotPasswordPage';
import ResetPasswordPage from 'pages/auth/resetPassword/ResetPasswordPage';
import ConfirmEmailPage from 'pages/auth/confirmEmail/ConfirmEmailPage';
import OnboardingPage from './pages/onboarding/OnboardingPage';



const router = createBrowserRouter([
    {
        path: '/login',
        element: <LoginPage />
    },
    {
        path: '/register',
        element: <RegisterPage />
    },
    {
        path: '/forgot_password',
        element: <ForgotPasswordPage />,
    },
    {
        path: '/reset_password',
        element: <ResetPasswordPage />,
    },
    {
        path: '/confirm_email',
        element: <ConfirmEmailPage />,
        loader: confirmEmail,
    },
    {
        path: '',
        element: <Page />,
        errorElement: <ErrorBoundary />,
        loader: me,
        children: [
            {
                path: '/',
                element: <DashboardPage/>,
            },
            {
                path: '/users',
                element: <UsersPage />,
                loader: users,
            },
            {
                path: '/profile',
                element: <ProfilePage/>,
            },
            {
                path: '/onboarding',
                element: <OnboardingPage/>,
            },
            {
                path: '*',
                element: <Navigate to={'/'} replace />,
            },
        ]
    },
]);

function App() {
    return (<RouterProvider router={router} />);
}

export default App;
