import React from 'react';
import {Card, Col, Row} from 'antd';
import {DollarOutlined, UserOutlined, HeartOutlined, ShoppingCartOutlined} from '@ant-design/icons';

import NumberCard from './NumberCard';
import BarChart from './BarChart';
import LineChart from './LineChart';


const count = [
    {
        today: "Today’s Sales",
        title: "$53,000",
        percent: "+30%",
        icon: DollarOutlined
    },
    {
        today: "Today’s Users",
        title: "3,200",
        percent: "+20%",
        icon: UserOutlined
    },
    {
        today: "New Clients",
        title: "+1,200",
        percent: "-20%",
        icon: HeartOutlined
    },
    {
        today: "New Orders",
        title: "$13,200",
        percent: "10%",
        icon: ShoppingCartOutlined
    },
];


const DashboardPage = () => {
    return (
        <div>
            <h2>Dashboard</h2>

            <div className="layout-content">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {count.map((c, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}
                            className="mb-24"
                        >
                            <NumberCard
                                title={c.today}
                                value={c.title}
                                percent={c.percent}
                                icon={c.icon}
                            />
                        </Col>
                    ))}
                </Row>
            </div>

            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
                    <Card bordered={false} className="circlebox h-full">
                        <BarChart />
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
                    <Card bordered={false} className="circlebox h-full">
                        <LineChart />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default DashboardPage;
