import {useLocalStorage} from './useLocalStorage';

export type AuthToken = {
    access_token: string,
    token_type: 'bearer'
}

export const useAuthToken = () => {
    const [authToken, setAuthToken] = useLocalStorage('token', null);
    return [authToken as (AuthToken | null), setAuthToken as ((authToken: AuthToken | null) => void)] as const;
}