import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';

import { Layout, theme } from 'antd';
import SideMenu from './SideMenu';
import Profile from './Profile';

const { Header, Content, Footer, Sider } = Layout;

const Page = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorPrimary, colorBgContainer},
    } = theme.useToken();

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <Header style={{background: colorPrimary}} />
                <SideMenu />
            </Sider>
            <Layout>
                <Header style={{ padding: '0 16px', background: colorBgContainer, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Profile />
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <Outlet/>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Proprietary & Confidential
                </Footer>
            </Layout>
        </Layout>
    );
}
export default Page
