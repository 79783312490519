import {redirect} from 'react-router-dom';
import {getToken} from 'utils';

function buildUrl(baseUrl: string, options?: {expand?: string[]}) {
    const searchParams = new URLSearchParams(window.location.search);

    let url = `${process.env.REACT_APP_API_URL}${baseUrl}`;

    const params = [];
    if (options && options.expand) {
        for (const expand of options.expand) {
            params.push(`expand=${expand}`);
        }
    }

    if (searchParams.has('limit')) {
        params.push(`limit=${searchParams.get('limit')}`);
    }
    if (searchParams.has('offset')) {
        params.push(`offset=${searchParams.get('offset')}`);
    }

    if (params) {
        url += '?' + params.join('&');
    }
    return url;
}

async function get(url: string, options?: {expand?: string[]}): Promise<Response> {

    const token = getToken();
    if (!token) {
        return new Promise((_, reject) => reject(redirect('/login')));
    }

    return fetch(
        buildUrl(url, options),
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token.access_token}`
            },
        }
    ).then(res => {
        if (res.status !== 200) {
            if (res.status === 401) {
                return new Promise((_, reject) => reject(redirect('/login')));
            }
            throw res;
        }
        return res;
    });
}

async function request(method: string, url: string, data: any, options?: {expand?: string[]}): Promise<Response> {
    const token = getToken();
    if (!token) {
        return new Promise((_, reject) => reject(new Response('Unauthorized', {status: 403})));
    }

    return fetch(
        buildUrl(url, options),
        {
            method: method,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token.access_token}`
            },
        }
    ).then(res => {
        if (res.status >= 300) {
            throw res;
        }
        return res;
    });
}

async function post(url: string, data: any, options?: {expand?: string[]}): Promise<Response> {
    return request('POST', url, data, options);
}

async function patch(url: string, data: any, options?: {expand?: string[]}): Promise<Response> {
    return request('PATCH', url, data, options);
}

const apiClient = {
    get,
    patch,
    post,
}

export default apiClient;