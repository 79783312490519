import {Form, Button, Typography, message} from 'antd';

import type {User} from 'types';
import {getErrorMessage} from 'utils';
import PasswordFormItem from 'components/forms/PasswordFormItem';
import PasswordConfirmFormItem from 'components/forms/PasswordConfirmFormItem';

type ResetPasswordRequest = {
    password: string,
}

const ResetPasswordForm = (props: {
    token: string;
    onReset: (data: User) => void;
    onError?: (status: number, message: string) => void;
}) => {
    async function onFinish(values: ResetPasswordRequest) {
        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/reset_password`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: props.token,
                        password: values.password,
                    }),
                    headers: {'Content-Type': 'application/json'}
                }
            );
            if (res.status !== 200) {
                const error = await res.json();
                const errorMessage = error.detail ? error.detail: res.statusText;
                props.onError ? props.onError(res.status, errorMessage) : message.error(res.statusText);
            } else {
                props.onReset(await res.json() as User);
            }
        } catch (e) {
            message.error(<><b>[Unknown Network Error]</b> {getErrorMessage(e)}</>);
        }
    }

    return (
        <Form
            name="register"
            className={'auth-form'}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Typography.Title>Reset Password</Typography.Title>
            <PasswordFormItem />
            <PasswordConfirmFormItem />

            <Form.Item>
                <Button type="primary" htmlType="submit" className="register-form-button">
                    Reset Password
                </Button>
            </Form.Item>
        </Form>
    );
}

export default ResetPasswordForm;
