import type {User} from 'types';
import {getFirstQueryParam} from '../utils';

export async function confirmEmail() {
    const token = getFirstQueryParam('t');
    if (!token) {
        return new Promise((resolve) => resolve(null));
    }

    let url = `${process.env.REACT_APP_API_URL}/auth/confirm_email`;
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({token}),
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(res => {
            if (res.status >= 300) {
                throw res;
            }
            return res.json() as Promise<User>
        })
        .then(user => {
            return user;
        })
        .catch(() => {
            return null;
        });
}
