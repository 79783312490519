import {Form, Button, Typography, message} from 'antd';

import {baseUrl, getErrorMessage} from 'utils';
import PasswordConfirmFormItem from 'components/forms/PasswordConfirmFormItem';
import PasswordFormItem from 'components/forms/PasswordFormItem';
import EmailFormItem from 'components/forms/EmailFormItem';

type RegisterFormRequest = {
    email: string,
    password: string,
    first_name: string | null,
    last_name: string | null,
}

export type RegisterResponse = {
    account_id: string,
    id: string,
    email: string,
    first_name: string|null,
    last_name: string|null,
}

const RegisterForm = (props: {
    onRegister: (data: RegisterResponse) => void
}) => {
    async function onFinish(values: RegisterFormRequest) {
        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/register`,
                {
                    method: 'POST',
                    body: JSON.stringify({...values, base_url: baseUrl()}),
                    headers: {'Content-Type': 'application/json'}
                }
            );
            if (res.status !== 200) {
                message.error(res.statusText);
            } else {
                props.onRegister(await res.json() as RegisterResponse);
            }
        } catch (e) {
            message.error(<><b>[Unknown Network Error]</b> {getErrorMessage(e)}</>);
        }
    }

    return (
        <Form
            name="register"
            className={'auth-form'}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Typography.Title>Register</Typography.Title>
            <EmailFormItem />
            <PasswordFormItem />
            <PasswordConfirmFormItem />

            <Form.Item>
                <Button type="primary" htmlType="submit" className="register-form-button">
                    Register
                </Button>
            </Form.Item>
        </Form>
    );
}

export default RegisterForm;
