import React from 'react';
import styles from './NumberCard.module.scss';

import {Card, Col, Row, Typography} from 'antd';
import {AntdIconProps} from '@ant-design/icons/lib/components/AntdIcon';

const {Title} = Typography;

const NumberCard = (props: {
    title: string,
    value: string,
    percent?: string,
    icon?:  React.ForwardRefExoticComponent<Omit<AntdIconProps, 'ref'> & React.RefAttributes<HTMLSpanElement>>
}) => {
    const percentClassName = props.percent && props.percent.startsWith('-') ? 'text-red' : 'text-green';

    return (
        <Card bordered={false} className={styles.card + ' circlebox'}>
            <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                    <Col xs={props.icon ? 18 : 24}>
                        <span>{props.title}</span>
                        <Title level={3}>
                            {props.value}
                            {props.percent ? <small className={percentClassName}>  {props.percent}</small> : null}
                        </Title>
                    </Col>
                    {props.icon ?
                        <Col xs={6}>
                            <div className="icon-box"><props.icon /></div>
                        </Col> : null
                    }
                </Row>
            </div>
        </Card>
    );
}

export default NumberCard;
