import {Typography} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import LoginForm from './LoginForm';

import {AuthToken, useAuthToken} from 'hooks/useAuthToken';
import AuthPage from 'components/AuthPage';

const {Text} = Typography;

function redirectURI() {
    const params = new URLSearchParams(document.location.search);
    const location = params.get('redirect_uri');
    return location ? location : '/';
}

const LoginPage = () => {
    const setToken = useAuthToken()[1];
    const navigate = useNavigate();

    function onLogin(token: AuthToken) {
        setToken(token);
        navigate(redirectURI())
    }

    return (
        <AuthPage>
            <LoginForm onLogin={onLogin}/>
            <Text>Don't have an account? <Link to={'/register'}>Sign Up</Link></Text>
            <Text><Link to={'/forgot_password'}>Forgot your password?</Link></Text>
        </AuthPage>
    );
}

export default LoginPage;
