import ReactApexChart from 'react-apexcharts';
import {Typography} from 'antd';
import {MinusOutlined} from '@ant-design/icons';

import styles from './LineChart.module.scss';
import lineChart from './configs/lineChart';

function LineChart() {
    const {Title, Paragraph} = Typography;

    return (
        <>
            <div className={styles.chartInfo}>
                <div>
                    <Title level={5}>Active Users</Title>
                    <Paragraph className="lastweek">
                        than last week <span className="text-green">+30%</span>
                    </Paragraph>
                </div>
                <div className="sales">
                    <ul>
                        <li>{<MinusOutlined/>} Traffic</li>
                        <li>{<MinusOutlined/>} Sales</li>
                    </ul>
                </div>
            </div>

            <ReactApexChart
                className={styles.chart}
                options={lineChart.options as ApexCharts.ApexOptions}
                series={lineChart.series}
                type="area"
                height={350}
                width={'100%'}
            />
        </>
    );
}

export default LineChart;
