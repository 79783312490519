import React from 'react';
import {Menu, MenuProps} from 'antd';
import {Link, useLocation} from 'react-router-dom';
import {FundOutlined, UserOutlined} from '@ant-design/icons';

import {useCurrentUser} from 'hooks/useCurrentUser';
import {isAdmin} from 'utils';

const SideMenu = () => {
    const location = useLocation();
    const currentUser = useCurrentUser();

    const items: MenuProps['items'] = [
        {
            label: <Link to="/">Dashboard</Link>,
            key: '/',
            icon: <FundOutlined/>,
        },

    ];

    if (isAdmin(currentUser)) {
        items.push(
            {
                label: <Link to="/users">Users</Link>,
                key: '/users',
                icon: <UserOutlined/>,
            }
        )
    }

    return <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        items={items}
    />
}

export default SideMenu;
