import {useState} from 'react';
import {List, Modal, Form, message} from 'antd';

import {User} from 'types';
import {updateMe} from 'services/me';
import {getErrorMessage} from 'utils';

import {useCurrentUser} from 'hooks/useCurrentUser';
import RolesBadges from 'components/RolesBadges';
import PageHeading from 'components/PageHeading';
import EditProfileForm from './EditProfileForm';

type FieldValues = {
    first_name: string,
    last_name: string
};

const Heading = (props: {
    user: User,
    onSave: (values: FieldValues) => void
}) => {
    const [form] = Form.useForm();
    const [showModal, setShowModal] = useState(false);

    function onOk() {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                props.onSave(values as FieldValues);
                setShowModal(false)
            });
    }

    return (
        <PageHeading title={'Profile'} onClick={() => setShowModal(true)}>
            <Modal
                title="Edit Profile"
                open={showModal}
                onOk={onOk}
                onCancel={() => setShowModal(false)}
            >
                <EditProfileForm form={form} user={props.user}/>
            </Modal>
        </PageHeading>
    );
};


const ProfilePage = () => {
    const currentUser = useCurrentUser();

    async function onSave(value: FieldValues) {
        return updateMe(value)
            .catch(e => {
                message.error((
                    <><b>[Unexpected Error]</b> {getErrorMessage(e)}</>
                ));
            });
    }

    if (!currentUser) {
        return null;
    }

    return (
        <List
            itemLayout="horizontal"
            header={<Heading user={currentUser} onSave={onSave}/>}
        >
            <List.Item>
                <List.Item.Meta
                    title={'Email'}
                    description={currentUser.email}
                />
            </List.Item>
            <List.Item>
                <List.Item.Meta
                    title={'First Name'}
                    description={currentUser.first_name ? currentUser.first_name : '-'}
                />
            </List.Item>
            <List.Item>
                <List.Item.Meta
                    title={'Last Name'}
                    description={currentUser.last_name ? currentUser.last_name : '-'}
                />
            </List.Item>
            <List.Item>
                <List.Item.Meta
                    title={'Account'}
                    description={currentUser.account?.name}
                />
            </List.Item>
            <List.Item>
                <List.Item.Meta
                    title={'Roles'}
                    description={<RolesBadges account={currentUser.account} roles={currentUser.roles}/>}
                />
            </List.Item>
        </List>
    );
}

export default ProfilePage;
