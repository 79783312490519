import type {User} from './types';

export const SYSTEM_ACCOUNT = '000000000000000000000000';

export function isString(s: any) {
    return (typeof s === 'string' || s instanceof String);
}

export function getErrorMessage(error: unknown): string {
    const {message} = error as Error;
    if (message) {
        return message;
    }
    try {
        return isString(error) ? (error as string) : JSON.stringify(error);
    } catch {
        return String(error);
    }
}

export function getToken() {
    const token = window.localStorage.getItem('token');
    try {
        return token ? JSON.parse(token) : null;
    } catch (e) {
        return null;
    }
}

export function isAdmin(user: User | null) {
    if (user && user.roles) {
        for (const role of user.roles) {
            if (['admin', 'super-admin'].includes(role.name)) {
                return true;
            }
        }
    }
    return false;
}

export function getFirstQueryParam(name: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

export function baseUrl() {
    return window.location.origin;
}

export function is_super_admin(user: User) {
    if (user.roles && user.account && user.account.id === SYSTEM_ACCOUNT) {
        for (const role of user.roles) {
            if (role.name === 'super-admin') {
                return true;
            }
        }
    }
    return false;
}
